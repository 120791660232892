<template>
  <b-container fluid>
    <b-row v-if="this.tokenIsValid">
      <b-col class="mt-auto" offset="3" md="6">
        <b-card class="mt-5" bg-variant="dark" body-text-variant="light" :title="$t('RESET_PASSWORD_PAGE_TITLE') | capitalize">
          <b-card-text>
            <b-form @submit.prevent="reset">

              <b-form-group id="password" :label="$t('USER_PASSWORD_LABEL') | capitalize">
                <b-form-input type="password" v-model.trim="$v.form.password.$model" :state="state('password')"></b-form-input>
              </b-form-group>


              <b-form-group id="verification" :label="$t('USER_PASSWORD_VERIFICATION_LABEL') | capitalize">
                <b-form-input type="password" v-model.trim="$v.form.verification.$model"
                              :state="state('verification')"></b-form-input>
              </b-form-group>


              <b-button class="d-block w-100" :disabled="$v.form.$invalid || resetting" variant="primary" type="submit">
                {{ $t('RESET_PASSWORD_BUTTON_LABEL')  | capitalize}}
              </b-button>
            </b-form>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-alert variant="danger">
        {{$t('PASSWORD_TOKEN_EXPIRED_MESSAGE') | capitalize}}
      </b-alert>
    </b-row>
  </b-container>
</template>

<script>
import {maxLength, minLength, required, sameAs} from "vuelidate/lib/validators";
import Users from "@/users";
import Constants from "@/constants";
import Utils from "@/utils";
import {SendsMessages} from "@/mixins";
import jwt_decode from "jwt-decode";

export default {
  name: "Reset",
  mixins: [SendsMessages],
  mounted() {
    this.token = this.$route.query.token;
    this.jwt = jwt_decode(this.token);
    if (!this.tokenIsValid) {
      this.sendError('PASSWORD_TOKEN_EXPIRED', {}, undefined);
    }
  },
  validations: {
    form: {
      password: {
        required, minLength: minLength(4), maxLength: maxLength(32)
      },
      verification: {
        required, minLength: minLength(4), maxLength: maxLength(32), sameAsPassword: sameAs('password')
      },
    }
  },
  data() {
    return {
      form: {
        password: undefined,
        verification: undefined
      },
      accessToken: undefined,
      jwt: undefined,
      resetting: false
    }
  },
  methods: {
    reset: async function () {
      try {
        this.resetting = true;
        await Users.setNewPassword(this.form.password, this.form.verification, this.token);
        this.sendMessage('RESET_MAIL_SUCCESS_MESSAGE', this.form);
        await this.$router.push({path: '/login'});
      } catch (e) {
        if (Constants.DEBUG) {
          console.error(e);
        }
        this.sendError('RESET_MAIL_ERROR_MESSAGE', {}, e);
      } finally {
        this.resetting = false;
      }
    }
  },
  computed: {
    state() {
      return Utils.state(this.$v);
    },
    tokenIsValid() {
      return this.jwt ? Date.now() < this.jwt.exp * 1000 : true;
    }
  }
}
</script>

<style scoped>

</style>
